import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-diamond"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>JESS LOPEZ</h1>
        {/* <p>
          A fully responsive site template designed by{' '}
          <a href="https://html5up.net">HTML5 UP</a> and released
          <br />
          for free under the{' '}
          <a href="https://html5up.net/license">Creative Commons</a> license.
        </p> */}
        <h3>
          Full-stack developer (React/Node specialist)
        </h3>
        <p>
          Goal oriented problem solver with a passion for technology and
          self-driven life-long learning. <br />
        </p>
        <p>
          <a
            href="https://github.com/jess-lopez"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
          <a
            href="https://drive.google.com/file/d/1OxY0HMTK9xUwFacoGBR7mO71bIwcebOY/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            - Resume -
          </a>
          <a
            href="mailto:jesslopez.dev@gmail.com"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            Email
          </a>
        </p>
      </div>
    </div>
    <nav>
      <ul>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('skills')
            }}
          >
            Skills
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('arbeatrary')
            }}
          >
            arbeatrary
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('watchnext')
            }}
          >
            Watch Next
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('launchbox')
            }}
          >
            LaunchBox
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('cryptocurrensea')
            }}
          >
            CryptocurrenSea
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
