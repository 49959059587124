import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import pic04 from '../images/pic04.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        role="button"
        onKeyDown={event => {
          console.log(event.key)
          if (event.key === 'Escape') {
            this.props.onCloseArticle()
          }
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
      
        <article
          id="arbeatrary"
          className={`${this.props.article === 'arbeatrary' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">arbeatrary</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            "Where do you want do eat?" A recurring dilemma when eating out is
            deciding where to eat. This app solves that. You enter the name of
            the city or use geolocation, the app can then give you a list and
            random suggestions of nearby food locations.
          </p>
          <p>
            <a
              href="https://arbeatrary.herokuapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>try the live demo</h4>
            </a>
          </p>

          <h4>Features</h4>
          <ul>
            <li>
              lists all restaurants within a certain radius of your location
            </li>
            <li>random food location suggestions button</li>
            <li>interactive map that</li>
            <li>renders 3D buildings based on zoom level</li>
            <li>instant search (city name suggestions, PH and AU)</li>
            <li>
              geolocation (currently only works on chromium-based browsers)
            </li>
          </ul>
          <h4>Technologies</h4>
          <ul>
            <li>React</li>
            <li>Redux for state managment</li>
            <li>MapBox</li>
            <li>Four Square Places</li>
            <li>Algolia Search</li>
            <li>Material-UI</li>
            <li>NodeJS</li>
            <li>Express</li>
          </ul>
          {close}
        </article>

        <article
          id="watchnext"
          className={`${this.props.article === 'watchnext' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Watch Next</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>A web app for discovering new movies and tv shows to watch.</p>
          <p>
            <a
              href="https://watchnext-app.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>try the live demo</h4>
            </a>
          </p>

          <ul>
            <h4>Features</h4>
            <li>Instant search</li>
            <li>Infinite scroll</li>
            <li>Reactive filtering based on year, genre, rating</li>
            <li>Weekly trending list</li>
            <li>Links to similar content</li>
            <li>Media info, trailers, images, cast, user reviews, </li>
          </ul>
          <ul>
            <h4>Technologies</h4>

            <li> ReactJS</li>
            <li> React-Router for client side routing</li>
            <li> RESTful API (TheMovieDB.org)</li>
            <li> Semantic UI React</li>
            <li> NodeJS backend</li>
            <li> Express</li>
          </ul>
          {close}
        </article>
        <article
          id="launchbox"
          className={`${this.props.article === 'launchbox' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Launch Box</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>

          <p>
            A web app for tracking current, previous, and future Space-X rocket
            launch. Also aggregates news related to the company.
          </p>
          <p>
            <a
              href="https://launchbox.netlify.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>try the live demo</h4>
            </a>
          </p>
          <ul>
            <h4>Features</h4>
            <li>Next launch countdown and info</li>
            <li>Past missions info, video and launch images</li>
            <li>Scheduled future missions</li>
            <li>spacex-related news</li>
          </ul>
          <ul>
            <h4>Technologies</h4>
            <li>React</li>
            <li>React-Router</li>
            <li>Material-UI</li>
            <li>REST API (unofficial space-x api)</li>
          </ul>
          {close}
        </article>
        <article
          id="cryptocurrensea"
          className={`${
            this.props.article === 'cryptocurrensea' ? 'active' : ''
          } ${this.props.articleTimeout ? 'timeout' : ''}`}
          style={{ display: 'none' }}
        >
          <h2 className="major">CryptocurrenSea</h2>
          <span className="image main">
            <img src={pic04} alt="" />
          </span>
          <p>
            A web application for monitoring the top 100 cryptocurrencies (in
            terms of market cap). It shows historical data, market statistics,
            interactive charts for technical analysis and crypto-to-fiat
            conversion.
          </p>

          <p>
            <a
              href="https://cryptocurrensea.netlify.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>try the live demo</h4>
            </a>
          </p>

          <ul>
            <h4>Features</h4>
            <li>Paginated table of top 100 cryptocurrencies</li>
            <li>Technical Analysis chart</li>
            <li>crypto-to-fiat conversions</li>
            <li>Interactive historical price chart</li>
            <li>Descriptions & market statistics</li>
          </ul>
          <ul>
            <h4>Technologies</h4>
            <li> ReactJS</li>
            <li> Redux for global state management</li>
            <li> React-Router for client-side routing</li>
            <li> Ant Design UI</li>
            <li> RESTful APIs (CoinGecko, TradingView)</li>
            <li> Frappe Charts for data visualization/charting</li>
          </ul>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />

            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-email"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/jess-lopez"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
